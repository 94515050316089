.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel {
  display: inline-flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.header-bg {
  background-image: url('./assets/Backgrounds/headerBackground.png'); 
  background-size: cover; 
  background-position: center; 
  width: 100%;
  height: 645px;
}

.community-header-bg {
  background-image: url('./assets/Backgrounds/communityHeaderBackground.png'); 
  background-size: cover; 
  background-position: center; 
  width: 100%;
  height: 645px;
}

.vision-bg {
  background-image: url('./assets/Backgrounds/visionBackground.png'); 
  background-size: cover; 
  background-position: center; 
  width: 100%;
  min-height: 645px;
}

.circles-bg {
  background-image: url('./assets/Backgrounds/circles.png'); 
  background-size: cover; 
  background-position: center; 
}

.colleges-header-bg {
  background-image: url('./assets/Backgrounds/Colleges/headerBackground.png'); 
  background-size: cover; 
  background-position: center; 
  width: 100%;
}


.description-text-size{
  font-size: 0.7rem;
}

@media only screen and (min-width: 1920px) {
  .description-text-size{
    font-size: 1rem;
  }  
}