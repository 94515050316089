@tailwind base;
@tailwind components;
@tailwind utilities;

/* IstokWeb font family */

@font-face {
  font-family: 'IstokWeb';
  src: url('./fonts/Istok_Web/IstokWeb-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'IstokWeb';
  src: url('./fonts/Istok_Web/IstokWeb-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'IstokWeb';
  src: url('./fonts/Istok_Web/IstokWeb-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'IstokWeb';
  src: url('./fonts/Istok_Web/IstokWeb-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Oswald font family */
@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-ExtraLight.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-Bold.ttf') format('truetype');
  font-weight: 700;
}


body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
